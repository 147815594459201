/* eslint-disable react/jsx-pascal-case */
import { RouteObject } from 'react-router-dom';
import paths from '@core/router/path';
import { Skeleton } from 'ni-ui-kit';

const routes: RouteObject = {
  path: '/',
  async lazy() {
    const DefaultLayout = await import('@core/layouts/Default');
    const ProtectedRoute = await import('@core/components/ProtectedRoute');
    return {
      element: (
        <ProtectedRoute.default>
          <DefaultLayout.default />
        </ProtectedRoute.default>
      ),
    };
  },

  children: [
    {
      path: '',
      async lazy() {
        const MyCards = await import('@core/pages/MyCards');
        const ResettableQueryErrorBoundary = await import(
          '@core/components/ResettableQueryErrorBoundary'
        );
        const PageLoadingFallback = await import(
          '@core/components/PageLoadingFallback'
        );
        return {
          element: (
            <ResettableQueryErrorBoundary.default
              LoadingFallback={<PageLoadingFallback.default />}
            >
              <MyCards.default />
            </ResettableQueryErrorBoundary.default>
          ),
        };
      },

      children: [
        {
          path: ':id',
          children: [
            {
              path: '',
              async lazy() {
                const ActionCard = await import(
                  '@core/pages/MyCards/components/ActionCard'
                );
                const CardControl = await import(
                  '@core/pages/MyCards/components/cardControl'
                );

                return {
                  element: (
                    <>
                      <ActionCard.default />
                      <CardControl.default />
                    </>
                  ),
                };
              },
            },
            {
              path: paths.home,
              async lazy() {
                const RecentTransactions = await import(
                  '@core/pages/MyCards/components/ResentTransactions'
                );
                const WalletDetails = await import(
                  '@core/pages/MyCards/components/WalletsDetails'
                );
                const ResettableQueryErrorBoundary = await import(
                  '@core/components/ResettableQueryErrorBoundary'
                );
                return {
                  element: (
                    <>
                      <ResettableQueryErrorBoundary.default
                        LoadingFallback={
                          <Skeleton active paragraph={{ rows: 10 }} />
                        }
                      >
                        <WalletDetails.default />
                        <RecentTransactions.default />
                      </ResettableQueryErrorBoundary.default>
                    </>
                  ),
                };
              },
            },
            {
              path: paths.cardTransactions,
              async lazy() {
                const TransactionTabs = await import(
                  '@core/pages/MyCards/components/TransactionTabs'
                );
                return {
                  element: <TransactionTabs.default />,
                };
              },
            },
            {
              path: paths.wallets,
              async lazy() {
                const AllWallets = await import(
                  '@core/pages/MyCards/components/AllWallets'
                );
                const ProtectedWalletRoute = await import(
                  '@core/components/ProtectedWalletRoute'
                );
                const ResettableQueryErrorBoundary = await import(
                  '@core/components/ResettableQueryErrorBoundary'
                );
                return {
                  element: (
                    <ProtectedWalletRoute.default>
                      <ResettableQueryErrorBoundary.default
                        LoadingFallback={
                          <Skeleton active paragraph={{ rows: 10 }} />
                        }
                      >
                        <AllWallets.default />
                      </ResettableQueryErrorBoundary.default>
                    </ProtectedWalletRoute.default>
                  ),
                };
              },
            },
            {
              path: paths.profile,
              async lazy() {
                const ResettableQueryErrorBoundary = await import(
                  '@core/components/ResettableQueryErrorBoundary'
                );
                const PageLoadingFallback = await import(
                  '@core/components/PageLoadingFallback'
                );
                const Profile = await import('@core/pages/Profile');
                return {
                  element: (
                    <ResettableQueryErrorBoundary.default
                      LoadingFallback={<PageLoadingFallback.default />}
                    >
                      <Profile.default />
                    </ResettableQueryErrorBoundary.default>
                  ),
                };
              },
            },
          ],
        },
      ],
    },
  ],
};

export default routes;
